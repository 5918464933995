@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
    font-family:  sans-serif;
}
.container{
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}
.header{
    height: 10%;
    background: white;
    width: 100%;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
    filter: drop-shadow(0px 0px 2px black);
}
.avatarContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 2%;
    overflow: auto;
    height: 85%;
    gap: 2%;
    background: #d4d3d3;
}
.avatarCard{
    width: 23.5%;
    height: max-content;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    filter: drop-shadow(0px 0px 1px #ccc);
    background: white;
    box-shadow: 0 2px 2px #1616162d;
    padding: 5px;
    /* border-bottom: 2px solid #1616167a; */
}
.imageConatiner{
    width: 100%;
    height: max-content;
}
.imageConatiner img{
    /* height: 80%; */
    width: 100%;
    object-fit: contain;
    background-color: #000;
    backdrop-filter: blur(10px);
    /* filter: drop-shadow(0 0 1px black); */
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0px 3px #ccc;
}
.detailsOfAvatar{
    padding: 1% 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* .detailsOfAvatar h2{
    width: 50%;
} */
.buttonsContainer{
    width: 55%;
    display: flex;
    justify-content: space-evenly;
}
.navLinksView{
    width: 47%;
    border-radius: 10px;
    text-align: center;
    padding: 2%;
    background: #161616;
    color: white;
    transition: 0.3s all ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.navLinksView:hover{
    background: #fff;
    color: #000;
    /* outline: 2px solid #ccc; */
}
.navLinksView a:hover p {
    color: black !important;
}
.navLinksView a:hover svg{
    color: black !important;
}
.navLinksView:active{
    transform: scale(0.9);
}

.navLinksView a p{
    color: white;
}
.navLinksView a{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.detailsOfAvatar > div{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8%;
}

.detailsOfAvatar > div > p{
    font-size: small;
    color: dodgerblue;
    font-weight: bold;
    margin-top: 3px;
}
.logoImg{
    height: 70%;
}
@media screen and (min-width: 1000px ) and (max-width: 1800px) {
    .avatarCard{
        width: 32%;
    }
}
@media screen and (max-width:1000px) {
    .avatarCard{
        width: 49%;
    }
}
@media screen and (max-width:650px) {
    .avatarCard{
        width: 100%;
    }
}