@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
  --canvasMargin: 0px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Montserrat", sans-serif; */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}
#homeContainer {
  padding: 1px;
}
#navbar {
  padding: 5px 15px;
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 1rem;
    /* box-shadow: 0 5px 5px gray; */
    height: 10vh;
    background: #161616;
    color: white;
    display: flex;
    align-items: center;
    filter: drop-shadow(0px 0px 3px black);
}
.mainCOntainer {
  height: max-content;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.mainCOntainer a{
  width: 24%;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1.2em;
    padding: 1%;
    color: black;
    font-weight: bold;
}
.mainCOntainer a:hover{
  color: white;
  background: #161616;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}

canvas {
  margin: var(--canvasMargin);
  min-height: calc(100vh - 2 * var(--canvasMargin));
  max-height: calc(100vh - 2 * var(--canvasMargin));
  min-width: calc(100vw - 2 * var(--canvasMargin));
  max-width: calc(100vw - 2 * var(--canvasMargin));
  /* border: 3px solid rgb(98, 98, 98); */
  border-radius: 5px;
  /* box-shadow: 0 0 5px rgb(4, 153, 9); */
  /* background-color: rgb(30, 30, 30); */
}

.healthInfoCardReqDetails p{
  background: #00504f;
  color: white;
  padding: 5px;
  border-radius: 5px;
  /* font-weight: bold; */
  margin-bottom: 3px;
}

.slide-up {
	transition: transform 0.5s ease-out;
	/* transform: translateY(100%); */
  animation: slideUp 0.7s ease-out forwards;
  }

  @keyframes slideUp {
  from { transform: translateY(15px); opacity: 0; filter: brightness(0.6);}
  to {transform: translateY(0px); opacity: 1; filter: brightness(1);}
  }

.insuranceReport p{
  text-align: center;
  }
