.askedQuestion {
    padding: 0.5em;
    border-radius: var(--chatResponseBorderRadius);
    max-width: 90%;
    background-color: #fff;
    margin-left: auto;
    filter: drop-shadow(0 0 2.5px #161616);
    font-style: italic;
    color: black;
}

.regular {}

.oneNewSectionForHeadAndRead {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: flex-end;
}

.contentToReadLoud {
    width: 100%;
    padding-right: 6px;
    text-align: left;
    overflow: auto;
}

.responseHead {
    font-size: 1.25em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 2px;
}

.responseHead * {
    cursor: pointer;
}

.copyContent {
    color: rgb(5, 5, 31);
}

.contentCopied {
    color: rgb(5, 126, 39);
}