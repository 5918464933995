.main-container{
    width: 35%;
    height: 95%;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 0px 3px black);
    overflow: auto;
    row-gap: 10px;
    transition: all 0.5s ease-out;
}
.main-container::-webkit-scrollbar {
    display: none;
}
.exampleListContainer{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    row-gap: 5px;
    flex-direction: column;
}

.questionTitle {
    width: 100%;
    background: #fff;
    padding: 1.5%;
    color: #111a17;
    font-size: 1.1em;
    font-weight: 600;
    border-radius: 5px;
}

.exampleListContainer li {
    font-size: 1em;
    padding: 1% 2% 1% 1%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    filter: drop-shadow(0px 0px 1px black);
    column-gap: 10px;
    border-bottom: 3px solid #ffffff14;
    cursor: pointer;
}

ul.exampleListContainer li i {
    cursor: pointer;
    /* border: 1px solid #ccc; */
    padding: 3px;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    display: grid;
    place-items: center;
    background: #ffffff14;
    transition: all 0.2s ease-out;
    backdrop-filter: blur(5px);
}

ul.exampleListContainer li i:hover{
    color: black;
    background: lightgoldenrodyellow;
    border: 1px solid #33333383;
    border-radius: 5px;
}
ul.exampleListContainer li i:active{
    transform: scale(0.8);
    background: #33333321;
    color: #fff;
}

@media screen and (max-width: 860px){
    .main-container{
        height: 100%;
        position: absolute;
        width: 100%;
        transform: translateX(100%);
        background: #161616;
        padding-top: 40px;
        
    }
    .questionsVisible > div{
        width: 98%;
        margin: 0 auto;
    }
}
.questionsVisible{
    transform: translateX(0%);
}