

.chatContainer{
	position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3% 3% 0 3%;
}
.chatMsgContainer {
	height: calc(98% - 60px);
	width: 100%;
	overflow-y: scroll;
}
.chatMsgContainer::-webkit-scrollbar {
	display: none;
}

.speakText {
	width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 50px;
    filter: drop-shadow(0px 0px 1px black);
    color: black;
	border: 1px solid #ccc;
    border-radius: 20px;
    background: #33333359;
	padding: 0 5px;
}
.text {
	width: 83%;
	padding: 4px 7px;
	background: transparent;
	color: #fff;
	border: none;
	border-radius: 25px;
	font-size: 1.1em;
	height: 60%;
	resize: none;
}
.text::-webkit-scrollbar {
	display: none;
}
.actionIcons {
	width: 95%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.actionIcons textarea{
	width: 100%;
	min-height: 60px;
    resize: none;
    max-height: 120px;
    overflow: auto;
    border: none;
    background: none;
    color: #fff;
	font-size: 1.2em;
	outline: none;
	box-shadow: none;
	padding: 10px;
	
}
.actionIcons textarea::-webkit-scrollbar{
	display: none;
}
.actionIcons textarea::placeholder{
	color: white;
}
.actionIcons textarea:hover{
	outline: none;
	box-shadow: none;
}
.text:focus {
	outline: none;
}
.text::placeholder {
	color: #fff;
	font-size: 1em;
	line-height: 50%;
}

.chatElement {
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 10px;
	padding: 2%;
	list-style-type: none;
}
.chatElement > li {
	padding: 2%;
	width: 100%;
	background: #ffffffed;
	/* backdrop-filter: blur(10px); */
	width: max-content;
	text-wrap: wrap;
	max-width: 100%;
	border-radius: 25px;
	filter: drop-shadow(0px 0px 2px #161616) brightness(97%);
	overflow: auto;
	/* color: black; */
}
.chatElement > li ul{
	list-style-type: none;
}
.chatElement > li ul > li{
	padding: 5px;
    border-collapse: collapse;
    margin-bottom: 2px;
    border-radius: 5px;
    background: #fafad2b0;
    filter: drop-shadow(0px 0px 1px #ccc);
    box-shadow: 0 0 2px #ccc;
}
.chatElement h1,.chatElement h2,.chatElement h3, .chatElement h4 {
    background: #e0ecec;
    border-radius: 10px;
    color: #233e3e;
    margin: 5px 0;
    filter: drop-shadow(0px 0px 2px #233e3e);
	text-align: center;
}
.chatElement table{
	background: #e1ecec;
	border: none;
	border-collapse: collapse;
	border-radius: 10px;
	overflow: hidden;
	margin-top: 5px;
	width: 100%;
}
.chatElement tr th{
	background: #253e3e;
    color: white;
    text-shadow: 0 0 2px black;
}

.send {
	align-self: end;
	background: #233e3e !important;
	color: #fff !important;
	overflow: visible !important;
	word-break: break-all;
	position: relative;
	display: flex;
	align-items: center;
	max-width: calc(100% - 45px) !important;
}

.copyNEdit{
	align-items: center;
    display: flex;
    justify-content: space-around;
    left: -45px;
    position: absolute;
    width: 40px;
}

.copyNEdit span{
	color: #fff;
	cursor: pointer;
	transition: all 0.3s ease-out;
}

.copyNEdit span:active{
	transform: scale(0.5);
}

.send:hover .editIcon{
	display: block !important;
}
.guidance{
	background: lightgoldenrodyellow !important;
    align-self: center;
    text-align: center;
    filter: drop-shadow(0px 0px 1px black) !important;
    /* font-size: 16px; */
    width: 95% !important;
}
.main .send{
    /* filter: drop-shadow(1px 1px 0px #fff); */
    text-shadow: 0px 1px black;
}
.receive{
	align-self: left;
}
.error{
	align-self: center;
	background: #880000 !important;;
	color: white;
}
.speakButton {
    cursor: pointer;
    margin: 3px;
    border: None;
    background: transparent;
    color: #dedede;
    filter: drop-shadow(0px 0px 1px black);
    padding: 0px;
	width: max-content;
	display: grid;
	place-items: center;

}

.speakButton:active {
	transform: scale(0.9);
}
.speakButton svg {
	height: 20px;
}

.speakButton:hover {
color: #8d8d8d;
}
.circledIcons{
	background: #ccc;
	color: black;
	border-radius: 15px;
	padding: 7px;
	transition: 0.2s all ease-out;
}
.circledIcons:hover{
	background: #161616 !important;
	color: #fff !important;
	outline: 1px solid #fff;
}

.loader1 {
	display: inline-block;
	font-size: 0px;
	padding: 0px;
	position: relative;
	z-index: 4 !important;
	width: 100%;
	text-align: center;
}
.loader1 span {
	vertical-align: middle;
	border-radius: 100%;

	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 3px 2px;
	animation: loader1 0.8s linear infinite alternate;
}
.loader1 span:nth-child(1) {
	animation-delay: -1s;
	background: rgba(255, 255, 255, 0.6);
}
.loader1 span:nth-child(2) {
	animation-delay: -0.8s;
	background: rgba(255, 255, 255, 0.8);
}
.loader1 span:nth-child(3) {
	animation-delay: -0.26666s;
	background: rgba(255, 255, 255, 1);
}
.loader1 span:nth-child(4) {
	animation-delay: -0.8s;
	background: rgba(255, 255, 255, 0.8);
}
.loader1 span:nth-child(5) {
	animation-delay: -1s;
	background: rgba(255, 255, 255, 0.6);
}

@keyframes loader1 {
	from {
		transform: scale(0, 0);
	}
	to {
		transform: scale(1, 1);
	}
}
.htmlData tr,
td {
	border-left: 2px dotted #333;
	text-align: center;
    border: none;
	outline: 1px solid #cfcfcf;
}
.main{
	height: 100vh;
	background: linear-gradient(to bottom, rgb(0 0 0 / 60%), rgb(16 16 16)), url(https://cutewallpaper.org/27/black-theme-with-white-border-wallpaper-desktop/1411612911.jpg);
	/* background: linear-gradient(to bottom, rgba(22,22,22, 0.5), rgba(22,22,22, 1)), #416155; */
	box-sizing: border-box;
	overflow: hidden;
}
.main canvas{
	min-height: 350px !important;
    max-height: 350px !important;
    min-width: 350px !important;
    max-width: 350px !important;
	position: absolute;
    left: 30%;
    top: 2%;
    transform: translate(-50%);
	object-fit: cover;
	border: 4px solid #fff;
    border-radius: 50%;
	filter: drop-shadow(rgb(255, 255, 255) 2px 4px 50px);
}

.chatContainer{
	bottom: 0;
    width: 100%;
    height: 100%;
	margin: unset !important;
	overflow: hidden;
}
.main .chatContainer .chatMsgContainer{
	height: 100%;
}
 .exampleContainer{
	display: none;
}
.exampleContainer .showQuesBtn{
	background: white;
	border: none;
	background: lightgoldenrodyellow;
	border-radius: 10px;
	padding: 1.5%;
	cursor: pointer;
}
.exampleContainer .showQuesBtn:active{
	transform: scale(0.8);
}
.cameraNuploadIcons{
	position: absolute;
    top: -95px;
    background: #ffffff21;
    backdrop-filter: blur(10px);
    width: 50px;
    height: 70px;
    display: grid;
    place-items: center;
    border-radius: 10px;
	opacity: 0;
	/* margin-top: 10px; */
	transition: all 0.2s ease-out;
	filter: drop-shadow(0px 0px 3px #161616);
	display: none;
}
/* .attachIcon:hover .cameraNuploadIcons{
	display: grid;
} */
.attachIcon:focus .cameraNuploadIcons{
	margin-top: 0;
	opacity: 1;
	display: block;
    position: absolute;
    background: #ffffff21;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    width: 50px;
    height: 70px;
    display: grid;
    place-items: center;
    border-radius: 10px;
    transition: all 0.2s ease-out;
    filter: drop-shadow(0px 0px 3px #161616);
}
.cameraStreamContainer{
	position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffff14;
    backdrop-filter: blur(5px);
    text-align: center;
    display: none;
}
.cameraStreamContainer video{
	width: 100%;
	margin: auto;
}
.displayCameraContainer{
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
.videoNcross{
	position: relative;
	width: 850px;
	z-index: 1;
}
.videoNcross video{
	border-radius: 20px 20px 0 0 ;
}
.crossVideoIcon{
	position: absolute;
	top: -15px;
	right: -15px;
	font-size: 30px;
	padding: 3px;
	color: #000;
	filter: drop-shadow(0px 0px 3px black);
	background: #ffffffd4;
	border-radius: 50%;
	cursor: pointer;
	z-index: 2;
}
.captureBtn{
	width: 850px;
	border-radius: 0 0 20px 20px;
	background: #f4f4f4;
	/* padding: 15px 0; */
	height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.singleIconContain{
	height: 100%;
	width: 100%;
	display: grid;
	place-items: center;
}


@media screen and (max-width: 979px) {
	.chatContainer{
		width: 100% !important;
		margin: 0 !important;
	}
	.main canvas{
		max-width: 300px !important;
		min-width: 300px !important;
		max-height: 300px !important;
		min-height: 300px !important;
	}
	
}
@media screen and (max-width: 860px){
	.main .mainChatHeightViewWithOutAvatar{
		height: 95% !important;
	}
   .chatHeightview{
        width: 100% !important;
    }
    .main canvas{
        left: 50%;
        transform: translateX(-50%) !important;
    }
    .main canvas{
        max-height: 200px!important;
        max-width: 200px!important;
        min-height: 200px!important;
        min-width: 200px!important;
    }
	/* .main .chatHeightview{
		height: calc(100% - 250px) !important;
	} */

	.main .mainChatHeightViewWithAvatar{
		height: calc(100% - 250px) !important;
		width: 100% !important;
	}
	
	.main .mainChatHeightViewWithOutAvatar{
		width: 95% !important; 
	}

	.main ,.chatContainer{
		padding: 0;
		display: flex;
		justify-content: center;
	}
	.exampleContainer{
		display: flex;
		justify-content: end;
		align-items: center;
		gap: 5px;
		position: fixed;
		top: 0;
		right: 0;
		width: 100%;
		height: 40px;
		padding: 1%;
		filter: drop-shadow(0px 0px 2px black);

	}
	.actionIcons{
		width: 100% !important;
	}
	.actionIcons button:nth-child(1), .actionIcons button:nth-child(2){
		display: none !important;
	}
	.actionIcons button:nth-child(3){
		border: none;
	}
	.main .avaEnableDisable{
		padding: 3px;
		border-radius: 50%;
		cursor: pointer;
	}
	.speakButton{
		margin: 2px !important;
	}
	.displayCameraContainer{
		justify-content: start !important;
		height: 100%;
		width: 100%;
	}
	.videoNcross{
		height: calc(100% - 70px);
		width: 100%;
	}
	.videoNcross video{
		height: 100%;
		width: unset;
	}
	.crossVideoIcon{
		top: 10px;
		right: 10px;
	}
	.mainChatHeightViewWithAvatar, .mainChatHeightViewWithOutAvatar{
		width: 100% !important;
	}
}
.chatHeightview{
	height: 100%;
	width: 35%;
	display: flex;
    flex-direction: column;
    justify-content: end;
	padding: 0 10px 10px;
	position: relative;
}

/* .main .chatHeightview{
	height: calc(100% - 400px);
    width: 45%;
    align-self: end;
} */

.mainChatHeightViewWithAvatar, .mainChatHeightViewWithOutAvatar{
    width: 45%;
	height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    row-gap: 5px;
    align-self: end;
	padding-bottom: 5px;
}

.main .mainChatHeightViewWithAvatar{
	height: calc(100% - 400px);
}

.main .mainChatHeightViewWithOutAvatar{
	height: 100%;
}

.fullmainChatHeightViewAvatar{
    width: 45%;
}

/* .fullmainChatHeightViewWithOutAvatar{
    width: 50% ;
} */

 .logoOrg{
	position: absolute;
    top: 4px;
    cursor: pointer;
    /* width: 14%; */
    display: flex;
    left: 5px;
    justify-content: space-evenly;
    height: 6%;
    align-items: center;
    background: #d4d3d3;
    border-radius: 10px;
}

.logoOrg img{
	width: 85%;
    height: 85%;
}

.rotateResetIcon{
	animation: rotateIcons 1s ease-out infinite;
}
.resetIconTop{
	position: absolute;
	top: 0;
	right: 0;
}

@keyframes rotateIcons {
	from{
		transform: rotate(0deg);
	}
	to{
		transform: rotate(360deg);
	}
   
}
.disabledButton {
	cursor: not-allowed !important;
	/* You can add additional styling for disabled button */
  }

  /* .micListeningTrue {
    animation: micOnAnimation 2s ease infinite;
} */

.micListeningFalse {
    background-color: #a8bee6;
}

/* @keyframes micOnAnimation {

    0%,
    100% {
        box-shadow: none;
        scale: 1;
    }

    50% {
		background: #3aa3c075;
		box-shadow: 0 0 0 2px #3aa3c075, 0 0 0 5px #3aa3c075, 0 0 0 6px #3aa3c075;
		scale: 1.15;
    }
} */

.withBackground {
	/* background-image: url('https://aihumans.ai/botImages/clinicbg3.jpg'); */
	background: linear-gradient(to top, rgba(22, 22, 22, 0.4), rgba(22, 22, 22, 1)), url('https://aihumans.ai/botImages/clinicbg3.jpg');
	background-size: cover;
	/* background: grey; */
  }
  

 