.heading {
    font-size: 2.5em;
    font-weight: bold;
    font-family: Math;
    text-decoration: underline;
}

.entry {
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    /* justify-content: space-between; */
    justify-content: flex-start;
}

.noQuestionFound {
    color: #ffd079;
    font-size: x-large;
}

.listOfTopics {
    padding: 5px 1rem;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.7em 0.7em;
    justify-content: flex-end;
    /* align-items: flex-start; */
    align-content: flex-start;
}

.eachGroup {
    background-color: rgba(230, 230, 230, 0.151);
    min-width: 320px;
    border-radius: 0 0 0.7em 0.7em;
    transition: box-shadow 100ms ease;
    flex: 1;
    overflow: hidden;
    min-height: 60px;
}

.eachGroup:hover {
    box-shadow: 0px 3px 3px rgba(196, 196, 196, 0.521);

}

.eachGroupHeading {
    background-color: #e9e9e9;
    margin-bottom: 0.3em;
    color: black;
    text-transform: capitalize;
    padding: 0.15em 0.6em;
    font-size: 1.2rem;
    min-height: 1em;
    font-weight: bold;
}

.eachGroupQuestions {
    /* background-color: aqua; */
    /* padding: 2px; */
}

.eachQ {
    /* background-color: red; */
    color: white;
    padding: 1px 6px 5px 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.eachQIcon1 {
    font-size: 1.25em;
    cursor: pointer;
}