.serverResponse {
    padding: 0.5em;
    border-radius: var(--chatResponseBorderRadius);
    max-width: 90%;
    background-color: #fff;
    font-size: 1.09em;
    margin-right: auto;
    filter: drop-shadow(0 0 2.5px #161616);
    color: black;
}

.oneNewSectionForHeadAndRead {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: flex-end;
}

.contentToReadLoud {
    text-transform: capitalize;
    width: 100%;
    padding-right: 6px;
    text-align: left;
    overflow: auto;
}

.responseHead {
    font-size: 1.25em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.2rem;
}

.timing {
    text-align: center;
    font-style: italic;
    font-size: small;
    pointer-events: none;
    user-select: none;
}

.responseHead * {
    cursor: pointer;
}

.copyContent {
    color: rgb(5, 5, 31);
}

.contentCopied {
    color: rgb(5, 126, 39);
}

.speakContent {
    color: rgb(5, 5, 31);
}

.pauseContent {
    color: rgb(200, 228, 255);

}